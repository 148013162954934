import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table'];

  static values = {
    paging: Boolean,
    lengthChange: Boolean,
    info: Boolean,
  };

  connect() {
    $(`#${this.tableTarget.id}`).DataTable({
      paging: this.pagingValue,
      info: this.infoValue,
      lengthChange: this.lengthChangeValue,
      drawCallback() {
        $('.pagination').addClass('mini-pagination');
      },
    });
  }
}
