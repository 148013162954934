import consumer from '../channels/consumer';
import BaseMapController from './base_map_controller';

export default class extends BaseMapController {
  async connect() {
    await super.connect();
    this.hasSetInitialCenter = false;
    this.initializeChannelSubscription();
  }

  initializeChannelSubscription() {
    this.subscription = consumer.subscriptions.create('OdMapChannel', {
      connected: () => {
        this.getData('markers_list');
      },
      received: (data) => {
        switch (data.type) {
          case 'update_list':
            this.updateMarker(data.markers, data.reference_marker);
            break;
          case 'markers_list':
            this.loadInitialMapData(data.markers);
            break;
          case 'add':
            this.addMarker(data.data);
            break;
          case 'update':
            this.getUpdatedList(data.data);
            break;
          case 'delete':
            this.deleteMarker(data.data);
            break;
          default:
            break;
        }
      },
    });
  }

  getData(actionType = 'markers_list', extraParams = {}) {
    const params = new URLSearchParams(window.location.search);
    const query = {
      start_date: params.get('start_date'),
      end_date: params.get('end_date'),
      open_runs: params.get('open_runs'),
      closed_runs: params.get('closed_runs'),
      type: actionType,
      ...extraParams,
    };
    this.subscription.perform('search', query);
  }
}
