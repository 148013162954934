import { Controller } from '@hotwired/stimulus';
import { withinSelectedFilter, parseDate } from './helpers/od_maps_helper';

export default class extends Controller {
  static targets = ['container', 'closed', 'date'];

  hide() {
    this.containerTarget.hidden = true;
  }

  connect() {
    const leaveDate = parseDate(this.dateTarget.innerText);
    const closedRun = this.closedTarget.innerText === 'true';
    if (!withinSelectedFilter(leaveDate, closedRun)) {
      this.hide();
    }
  }
}
