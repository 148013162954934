import Logger from '../../../helpers/logger';
import global from '../store/global';
import userSettings from '../store/user_settings';
import {
  replaceSynonyms,
  resumeOrPauseListening,
  shouldIgnoreTranscript,
} from './transcript_preparer';

class TranscriptEvaluator {
  call(transcript, tryCommandHandlers, tryInputHandlers) {
    const transcriptDownCase = transcript.toLowerCase();
    const allowVoice = userSettings.allowVoice();

    if (shouldIgnoreTranscript(
      transcriptDownCase,
      global.get('latest_transcript'),
      global.get('latest_utterance')
    )) {
      this.#setLatestTranscript(transcriptDownCase);
      return false;
    }

    this.#setLatestTranscript(transcriptDownCase);

    const updatedAllowVoice = resumeOrPauseListening(
      transcriptDownCase,
      allowVoice,
    );

    if (updatedAllowVoice !== allowVoice) {
      if (global.get('evaluate_only')) {
        return true;
      }

      userSettings.allowVoice(updatedAllowVoice);
      tryCommandHandlers(transcriptDownCase);
      return true;
    }

    if (allowVoice === false) {
      Logger.warn(`[IGNORED] "${transcript}"`);
      return false;
    }

    const transcriptWithSynonymsReplaced = replaceSynonyms(transcriptDownCase);

    if (
      tryCommandHandlers(transcriptWithSynonymsReplaced) ||
      tryInputHandlers(transcriptWithSynonymsReplaced)
    ) {
      return true;
    }

    if (global.get('evaluate_only')) {
      return false;
    }

    Logger.warn(`[NOOP] "${transcript}"`);
    return false;
  }

  // private

  #setLatestTranscript(transcript) {
    if (global.get('evaluate_only')) {
      return;
    }

    const allowRepeatWords = ['repeat', 'confirm'];

    if (allowRepeatWords.includes(transcript)) {
      global.set('latest_transcript', null);
      return;
    }

    global.set('latest_transcript', transcript);
  }
}

export default TranscriptEvaluator;
