import { Controller } from '@hotwired/stimulus';
import queue from '../store/speech_queue';
import userSettings from '../store/user_settings';

export default class extends Controller {
  static targets = ['message'];

  static values = {
    inputId: String,
    playOnPageLoad: Boolean,
    voiceSpeed: Number,
  };

  connect() {
    userSettings.voiceSpeed(this.voiceSpeedValue);

    if (!this.playOnPageLoadValue) {
      return;
    }

    // Delay for 1 sec to avoid this error: An unexpected error occurred during speech synthesis.
    setTimeout(() => {
      queue.push(this.#message(), this.messageTarget);
    }, 1000);
  }

  clickElement(evt) {
    evt.preventDefault();
    queue.push(this.#message(), this.messageTarget);
  }

  // private

  #message() {
    if (!this.message) {
      this.message = this.#getMessage();
    }

    return this.message;
  }

  #getMessage() {
    const instruction = this.messageTarget.dataset.speechSynthSpeechInstruction;
    return (instruction && instruction.trim()) || this.messageTarget.innerText;
  }
}
