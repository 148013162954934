const integerValue = (instruction) => {
  const wordMap = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    thirty: 30,
    forty: 40,
    fourty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90,
    hundred: 100,
    thousand: 1000,
    million: 1000000,
  };

  const words = instruction
    .toLowerCase()
    .replace(/-/g, ' ')
    .split(/\s+/);
  let result = 0;
  let currentNumber = null;
  const unusedWords = [];

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    const value = wordMap[word];

    if (value !== undefined) {
      if (value === 100) {
        // e.g. to handle five hundred thousand
        currentNumber = Number(currentNumber) * value;
      } else if (value === 1000 || value === 1000000) {
        currentNumber = (currentNumber || 1) * value;
        result += currentNumber;
        currentNumber = 0;
      } else {
        currentNumber = Number(currentNumber) + value;
      }
    } else {
      const numValue = parseInt(word, 10);
      if (Number.isNaN(numValue)) {
        unusedWords.push(word);
      } else {
        currentNumber = Number(currentNumber) + numValue;
      }
    }
  }

  if (currentNumber === null) {
    return { value: false, unusedWords: '' };
  }

  result += currentNumber;
  return { value: result, unusedWords: unusedWords.join(' ') };
};

export default integerValue;
