import Logger from '../../../helpers/logger';
import calculatedTranscript from './calculated_transcript';
import speechQueue from '../store/speech_queue';

class RecognitionEventProcessor {
  call(evt) {
    this.transcript = calculatedTranscript(evt);

    if (!this.#usableTranscript()) {
      return null;
    }

    Logger.warn(`\n--- APPLYING MATCHED TRANSCRIPT: "${this.transcript}" ---`);
    return this.#dispatchEvent();
  }

  // private

  #usableTranscript() {
    if (this.transcript === '') {
      return false;
    }

    if (speechQueue.wasUtterance()) {
      Logger.log(`[SPEECH LOOP REJECTED] "${this.transcript}"`);
      return false;
    }

    return true;
  }

  #dispatchEvent() {
    Logger.warn(`[DISPATCH EVENT] speechRecognitionResult: "${this.transcript}"`);

    return window.dispatchEvent(
      new CustomEvent('speechRecognitionResult', {
        detail: { transcript: this.transcript },
      }),
    );
  }
}

export default RecognitionEventProcessor;
