import DOM from '../helpers/dom_helper';
import Logger from '../../../helpers/logger';
import global from '../store/global';
import speechQueue from '../store/speech_queue';
import voiceInput from '../store/voice_input';

class VoiceInputHandler {
  call(params, autoSubmit) {
    this.autoSubmit = autoSubmit;
    this.params = params;
    this.matches = this.#evaluate();

    if (global.get('evaluate_only')) {
      return this.matches;
    }

    this.#applyToInputFields();
    this.#finalise();

    return this.matches;
  }

  // private

  #evaluate() {
    return voiceInput.call(this.params);
  }

  #applyToInputFields() {
    for (const { elementId, value, instruction } of this.matches) {
      this.form = DOM.setValueAndReturnForm(elementId, value);
      Logger.warn(`[INPUT APPLIED ✓] ${elementId}: "${value}"`);
      this.#speakBackUserInput(instruction);
    }
  }

  #speakBackUserInput(instruction) {
    if (this.#isAutoSubmit()) {
      return;
    }

    speechQueue.push(instruction);
  }

  #finalise() {
    if (this.#isAutoSubmit()) {
      this.form.submit();
      return;
    }

    if (this.matches.length === 0) {
      Logger.log(`[INPUT ✗] "${this.params.transcript}"`);
      speechQueue.push('Unrecognized, please repeat');
    }
  }

  #isAutoSubmit() {
    return this.autoSubmit && this.matches.length === 1;
  }
}

export default VoiceInputHandler;
