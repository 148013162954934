import Logger from './logger';

const generateHandlerObjectId = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

const intersects = (transcript, findString) => {
  if (transcript === findString) {
    return true;
  }

  if (transcript.includes(findString)) {
    return true;
  }

  if (findString && findString.includes(transcript)) {
    return true;
  }

  return false;
};

const matches = (transcript, findString) => {
  if (transcript.match(new RegExp(`(^|\\s)${findString}(\\s|$)`)) !== null) {
    Logger.warn(`[MATCH ✓] "${transcript}" matches "${findString}"`);
    return true;
  }
  Logger.warn(`[MATCH ✗] "${transcript}" not matching "${findString}"`);
  return false;
};

const separateCharactersIfInSquareBrackets = (message) => {
  return message.replace(/\[(.+?)\]/g, (match, content) => {
    return content.split('').join(' ');
  });
}

const normaliseMessage = (message) => {
  return message
    .toLowerCase()
    .replace(/[, ]+/g, ' ')
    .trim();
}

export default {
  generateHandlerObjectId,
  intersects,
  matches,
  normaliseMessage,
  separateCharactersIfInSquareBrackets,
};
