import consumer from '../channels/consumer';
import BaseMapController from './base_map_controller';
import { getIconPath, drawMarker } from './helpers/od_maps_helper';

export default class extends BaseMapController {
  async connect() {
    await super.connect();
    const { vanMarker } = this.element.dataset;
    this.vanMarkerData = JSON.parse(vanMarker);
    this.hasSetInitialCenter = false;
    this.initializeChannelSubscription();
  }

  initializeChannelSubscription() {
    this.subscription = consumer.subscriptions.create(
      { channel: 'RunMapChannel', id: this.element.dataset.runId },
      {
        connected: () => {
          this.getData('markers_list');
          drawMarker(this.vanMarkerData, this.map, 'van-marker', this.markers);
        },
        received: (data) => {
          switch (data.type) {
            case 'update_list':
              this.updateMarker(data.markers, data.reference_marker);
              break;
            case 'markers_list':
              this.loadInitialMapData(data.markers);
              break;
            case 'add':
              this.addMarker(data.data);
              break;
            case 'update':
              this.getUpdatedList(data.data);
              break;
            case 'delete':
              this.deleteMarker(data.data);
              break;
            case 'update_van':
              this.updateVanMarker(data.data);
              break;
            default:
              break;
          }
        },
      },
    );
  }

  getData(actionType = 'markers_list', extraParams = {}) {
    const { runId } = this.element.dataset;
    const query = {
      type: actionType,
      run_id: runId,
      ...extraParams,
    };
    this.subscription.perform('search', query);
  }

  updateVanMarker(record) {
    this.markers.find((m) => m.title === 'van-marker').setMap(null);
    this.addVanMarker(record);
  }

  addVanMarker(record) {
    const position = new google.maps.LatLng(record.lat, record.lng);

    const marker = new google.maps.Marker({
      position,
      map: this.map,
      title: 'van-marker',
      icon: getIconPath(record),
    });
    this.markers.push(marker);
  }
}
