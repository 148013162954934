import string from '../../../helpers/string_helper';
import Logger from '../../../helpers/logger';

const replaceSynonyms = (transcript) => {
  const knownCommands = [
    'ready to work',
  ];

  if (knownCommands.some((command) => string.matches(transcript, command))) {
    return transcript;
  }

  const synonymsOfTwo = ['too', 'to'];
  const synonymsOfThree = ['tree', 'free'];
  const synonymsOfFour = ['for'];

  const regex = (synonyms) => {
    const prepSynonyms = synonyms.map(
      (word) => `(^|\\s)${word}(\\s|$)`,
    );
    return new RegExp(prepSynonyms.join('|'), 'gi');
  };

  return transcript
    .replace(regex(synonymsOfTwo), ' two ')
    .replace(regex(synonymsOfThree), ' three ')
    .replace(regex(synonymsOfFour), ' four ')
    .replace(/\s+/g, ' ')
    .trim();
};

const resumeOrPauseListening = (transcript, allowVoice) => {
  if (string.matches(transcript, 'pause listening')) {
    return false;
  }

  if (string.matches(transcript, 'resume listening')) {
    return true;
  }

  return allowVoice;
};

const shouldIgnoreTranscript = (transcript, lastVoiceRecognised, latestUtterance) => {
  Logger.log({ current: transcript, prev: lastVoiceRecognised });

  const portionOfKnownFeedbackMessages = [
    'unrecognized',
    'on recognized',
  ];

  if (portionOfKnownFeedbackMessages.find((message) => transcript.includes(message))) {
    Logger.log(`[FEEDBACK REJECTED] "${transcript}"`);
    return true;
  }

  const partOfKnownInstructions = [
    'confirm ready to',
    'confirm you are',
    'enter package details',
    'go to',
    'is now complete',
    'locate order for packing',
    'no work',
    'nowhere',
  ];

  for (const instruction of partOfKnownInstructions) {
    if (transcript.includes(instruction)) {
      Logger.log(
        `[IGNORED, KNOWN INSTRUCTION CONTAINING COMMAND] "${transcript}"`,
      );
      return true;
    }
  }

  if (transcript === lastVoiceRecognised) {
    Logger.log(`[IGNORED, SAME AS LAST] "${transcript}"`);
    return true;
  }

  return false;
};

export { replaceSynonyms, resumeOrPauseListening, shouldIgnoreTranscript };
