import Logger from '../../../helpers/logger';
import Speaker from '../helpers/speaker';
import Store from '../../../store/store';
import string from '../../../helpers/string_helper';

class SpeechQueue extends Store {
  push(message, messageTarget = null) {
    this.initOnceQueue('queue');
    this.#deleteOldMessagesOnQueue();

    if (!message) {
      return this.queue;
    }

    this.#pushToQueue(message, messageTarget);

    return this.queue;
  }

  wasUtterance(transcript) {
    return (!!(this.queue && this.queue.find(
      ({ message, processedAt }) => message === transcript && processedAt,
    )));
  }

  // private

  #pushToQueue(message, messageTarget) {
    const normalisedMessage = string.normaliseMessage(message);

    const messageHash = this.#findUnprocessedMessageInQueue(normalisedMessage)

    if (messageHash) {
      Logger.warn(`[ALREADY ON QUEUE, RE-PROCESSING] "${message}"`);
    } else {
      this.queue.push({
        message: string.separateCharactersIfInSquareBrackets(message),
        normalisedMessage,
        messageTarget,
        processedAt: null,
        queue: this,
        onDone: function() {
          Logger.warn(`[SPOKEN] "${this.message}"`);
          this.processedAt = Date.now();
          this.queue.#processQueue();
        },
      });
    }

    this.#processQueue(messageHash);
  }

  #findUnprocessedMessageInQueue(transcript) {
    return this.queue.find((messageHash) => messageHash.processedAt === null && messageHash.normalisedMessage === transcript);
  }

  #processQueue(pMessageHash) {
    if (speechSynthesis.speaking) {
      // Currently speaking, retry in 1 sec
      setTimeout(() => {
        this.#processQueue(pMessageHash);
      }, 1000);
      return;
    }

    const messageHash = pMessageHash || this.#unprocessedMessage();

    if (messageHash) {
      (new Speaker()).call(messageHash);
    }
  }

  #unprocessedMessage() {
    return this.queue[this.#unprocessedMessageIndex()];
  }

  #unprocessedMessageIndex() {
    return this.queue.findIndex(({ processedAt }) => processedAt === null);
  }

  #deleteOldMessagesOnQueue() {
    const oldMessageAge = 5000;
    let { length } = this.queue;

    for (let i = 0; i < length; i += 1) {
      const { processedAt } = this.queue[i];

      if (processedAt && processedAt <= Date.now() - oldMessageAge) {
        this.queue.splice(i, 1);
        i -= 1;
        length -= 1;
      }
    }
  }
}

const speechQueue = new SpeechQueue();
export default speechQueue;
