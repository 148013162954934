import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];

  exportButtonToClipboard() {
    event.preventDefault(); // eslint-disable-line no-restricted-globals

    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([this.buttonHtml()], { type: 'text/html' }),
    });

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        alert('Button copied to clipboard');
      })
      .catch((error) => {
        alert('Error copying button:', error);
      });
  }

  linkUrl() {
    return this.linkTarget.innerText;
  }

  buttonHtml() {
    //  Not all styles work
    return `
      <a target="_blank"
        href="${this.linkUrl()}"
        style="
          font-weight: 400;
          color: #212529;
          padding: 0.375rem 0.75rem;
          background-color: #F8C66A !important;
          height: 52px;
          font-family: "Tenon Bold", Arial, sans-serif;
          font-size: 17px !important;
        "
      >
      Click here to view quotation
      </a>`;
  }
}
