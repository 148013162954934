import queue from '../store/event_handler_queue';
import speechProcessor from '../store/speech_processor';
import speechRecognition from '../store/speech_recognition';
import { Controller } from '@hotwired/stimulus';

class SpeechController extends Controller {
  connect(type) {
    const initialisingOnceNow = speechRecognition.start();

    queue.push(type, (transcript) =>
      this.speechHandler(transcript),
    );

    if (initialisingOnceNow) {
      window.addEventListener(
        'speechRecognitionResult',
        this.constructor.handler,
      );
    }
  }

  disconnect() {
    window.removeEventListener(
      'speechRecognitionResult',
      this.constructor.handler,
    );
    queue.clear();
    speechRecognition.stop();
  }

  static handler(event) {
    speechProcessor.call(event.detail.transcript.trim());
  }
}

export default SpeechController;
