function parseDate(dateString) {
  const parts = dateString.split(/-|\//);
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10),
  );
}

function customColourMarkerImage(hexColour, iconSize) {
  const pinSVGHole =
    'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z';
  const labelOriginHole = new google.maps.Point(12, 15);

  return {
    path: pinSVGHole,
    anchor: new google.maps.Point(12, 17),
    fillOpacity: 1,
    fillColor: hexColour || '#000000',
    strokeWeight: 2,
    strokeColor: 'white',
    scale: iconSize,
    labelOrigin: labelOriginHole,
  };
}

function runsFilter() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search.slice(1));
  const openRuns = params.get('open_runs') === '1';
  const closedRuns = params.get('closed_runs') === '1';
  const startDate = parseDate(params.get('start_date'));
  const endDate = parseDate(params.get('end_date'));

  return {
    startDate,
    endDate,
    closedRuns,
    openRuns,
  };
}

function getIconPath(record) {
  const iconSize = 2;
  const icon = record.icon_path
    ? record.icon_path
    : customColourMarkerImage(record.marker_hex_colour, iconSize);
  return icon;
}

function drawMarker(markerData, map, title, markers) {
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(markerData.lat, markerData.lng),
    map,
    title,
    icon: getIconPath(markerData),
  });
  markers.push(marker);
}

function withinSelectedFilter(leaveDate, closedRun) {
  const runsFilterHash = runsFilter();
  return (
    leaveDate >= runsFilterHash.startDate &&
    leaveDate <= runsFilterHash.endDate &&
    (runsFilterHash.closedRuns === closedRun ||
      runsFilterHash.openRuns === !closedRun)
  );
}

export {
  customColourMarkerImage,
  parseDate,
  runsFilter,
  withinSelectedFilter,
  drawMarker,
  getIconPath,
};
