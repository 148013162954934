import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'analyticsCheckbox',
    'hotjarCheckbox',
    'gatorLeadCheckbox',
    'acceptedCookieAt',
    'submitForm',
    'form',
  ];

  accept(event) {
    event.preventDefault();
    this.gatorLeadCheckboxTarget.checked = true;
    this.analyticsCheckboxTarget.checked = true;
    this.hotjarCheckboxTarget.checked = true;
    this.updateSession();
  }

  updateSession() {
    this.formTarget.submit();
  }

  showModal() {
    this.modalTarget.classList.add('show');
    this.modalTarget.style.display = 'block';
  }
}
