import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  connect() {
    const options = {
      allowInput: true,
      enableTime: this.getBooleanData('enableTime', false),
      dateFormat: this.getData('dateFormat', 'd/m/Y'),
      defaultDate: this.getData('defaultDate', null),
      minDate: this.getData('minDate', null),
      onKeyDown(selectedDates, dateStr, instance, event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
          if (instance.isOpen) {
            instance.close();
          }
        }
      },
    };
    flatpickr(this.element, options);
  }

  getData(key, defaultValue) {
    return this.element.dataset[key] || defaultValue;
  }

  getBooleanData(key, defaultValue) {
    return this.element.dataset[key] === 'true' || defaultValue;
  }
}
