/*
 * This loads the Google Maps API and returns a promise
 * that resolves when the API is loaded.
 * config: has the API key
 */
export function loadGoogleMapsApiAsync(config) {
  return new Promise((resolve, reject) => {
    if (typeof google !== 'undefined') {
      // Google Maps API script already loaded, resolve the promise
      resolve();
      return;
    }

    try {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${config.key}&callback=initializeOdMap`;
      script.async = true;
      script.defer = true;
      script.onerror = reject;
      document.head.appendChild(script);
      window.initializeOdMap = () => {
        resolve();
      };
    } catch (error) {
      reject(error);
    }
  });
}

export default {
  loadGoogleMapsApiAsync,
};
