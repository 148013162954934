import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="od-map-statistics"
export default class extends Controller {
  static targets = [
    'totalDrops',
    'ownVanDrops',
    'courierDrops',
    'unassignedDrops',
    'transportTime',
    'distance',
    'operationTime',
    'numberOfVehicles',
  ];

  refreshStats() {
    const url = `/v1/od_map_stats?${window.location.search.substring(1)}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const targets = {
          'Total Drops': this.totalDropsTarget,
          'Own Van Drops': this.ownVanDropsTarget,
          'Courier Drops': this.courierDropsTarget,
          'Unassigned Drops': this.unassignedDropsTarget,
          'Transport Time': this.transportTimeTarget,
          'Distance': this.distanceTarget, // prettier-ignore
          'Operation Time': this.operationTimeTarget,
          'Number of Vehicles': this.numberOfVehiclesTimeTarget,
        };

        data.forEach((stat) => {
          const target = targets[stat.name];
          if (target) {
            target.innerHTML = stat.value;
          }
        });
      });
  }

  connect() {
    this.refreshStats();
  }
}
