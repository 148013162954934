import { Controller } from '@hotwired/stimulus';
import buildSettings from './helpers/build_settings';

export default class extends Controller {
  static values = {
    id: String,
    path: String,
    placeholder: String,
    settings: String,
  };

  connect() {
    if (this.settingsValue) {
      $(this.idSelector()).select2(buildSettings(this.settingsValue));
    } else {
      initSelect2(this.idSelector(), this.pathValue, this.placeholderValue);
    }
  }

  idSelector() {
    return `#${this.idValue}`;
  }
}
