import Store from './store';
import * as Sentry from '@sentry/browser';

class SentryStore extends Store {
  start(data) {
    this.data = data;
    return this.#initOnce();
  }

  // private

  #initOnce() {
    if (this.sentryHasBeenInitialised) {
      return false;
    }

    this.#init();
    return true;
  }

  #init() {
    Sentry.init({
      dsn: this.data.get('dsn'),
      environment: this.data.get('environment'),
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setUser({
      email: this.data.get('userEmail'),
      name: this.data.get('userName'),
    });

    this.sentryHasBeenInitialised = true;
  }
}

const sentry = new SentryStore();
export default sentry;
