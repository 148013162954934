/* global Turbo, bootstrap */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    modalId: String,
  };

  modalElement() {
    return document.getElementById(this.modalIdValue);
  }

  open(event) {
    event.preventDefault();

    fetch(event.currentTarget.href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        const bootstrapModal = new bootstrap.Modal(this.modalElement());
        bootstrapModal.show();
      });
  }

  close() {
    const bootstrapModal = bootstrap.Modal.getInstance(this.modalElement());
    bootstrapModal.hide();
  }
}
