import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['viewPricesCheckbox', 'connectedToPriceCheckbox'];

  connect() {
    this.initPermissionCheckBoxListener();
  }

  initPermissionCheckBoxListener() {
    this.viewPricesCheckboxTarget.addEventListener('change', (event) =>
      this.updatePermissionCheckBoxes(event),
    );
  }

  updatePermissionCheckBoxes(event) {
    this.connectedToPriceCheckboxTargets.forEach((target) => {
      const checkbox = target;
      checkbox.disabled = !event.target.checked;

      const parentContainer = checkbox.closest('.checkbox-container');
      if (event.target.checked) {
        parentContainer.classList.remove('disabled');
      } else {
        checkbox.checked = false;
        parentContainer.classList.add('disabled');
      }
    });
  }
}
