import SpeechController from './speech_controller';
import browserSupport from '../helpers/speech_synthesis';
import global from '../store/global';
import string from '../../../helpers/string_helper';
import userSettings from '../store/user_settings';

class VoiceClickController extends SpeechController {
  static values = {
    allowVoice: Boolean,
  };

  connect() {
    if (!browserSupport()) {
      return;
    }
    super.connect('command');
    userSettings.allowVoice(this.allowVoiceValue);
  }

  speechHandler(transcript) {
    const result = this.#evaluate(transcript);

    if (global.get('evaluate_only')) {
      return result;
    }

    if (result) {
      this.element.click();
    }

    return result;
  }

  // private

  #evaluate(transcript) {
    const elementText = (
      this.element.innerText.trim() || this.element.value.trim()
    ).toLowerCase();

    return string.matches(transcript, elementText);
  }
}

export default VoiceClickController;
