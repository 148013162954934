import Logger from '../../../helpers/logger';
import Store from '../../../store/store';
import TranscriptEvaluator from '../helpers/transcript_evaluator';
import eventHandlerQueue from './event_handler_queue';
import global from './global';

class SpeechProcessor extends Store {
  call(transcript) {
    global.set('evaluate_only', false);
    return this.#utiliseVoiceForOneHandlerOnly(transcript);
  }

  evaluate(transcript) {
    global.set('evaluate_only', true);
    const evalResult = this.#utiliseVoiceForOneHandlerOnly(transcript);
    Logger.warn(`[EVAL ${evalResult ? '✓' : '✗'}]: "${transcript}"`);
    return evalResult;
  }

  // private

  #utiliseVoiceForOneHandlerOnly(transcript) {
    return this.#transcriptEvaluator().call(
      transcript,
      this.#tryCommandHandlers.bind(this),
      this.#tryInputHandlers.bind(this),
    );
  }

  #transcriptEvaluator() {
    if (!this.transcriptEvaluator) {
      this.transcriptEvaluator = new TranscriptEvaluator();
    }

    return this.transcriptEvaluator;
  }

  #tryCommandHandlers(transcript) {
    return eventHandlerQueue
      .commands()
      .find((command) => command(transcript));
  }

  #tryInputHandlers(transcript) {
    return eventHandlerQueue
      .inputs()
      .find((input) => input(transcript));
  }
}

const speechProcessor = new SpeechProcessor();

export default speechProcessor;
