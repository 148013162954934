import Store from '../../../store/store';

class EventHandlerQueue extends Store {
  push(type, object) {
    this.#initOnce();
    this.queue[type].push(object);
  }

  commands() {
    this.#initOnce();
    return this.queue.command;
  }

  inputs() {
    this.#initOnce();
    return this.queue.input;
  }

  clear() {
    this.#init();
  }

  // private

  #init() {
    this.queue = {
      command: [],
      input: [],
    };
  }

  #initOnce() {
    if (this.queue) {
      return;
    }

    this.#init();
  }
}

const eventHandlerQueue = new EventHandlerQueue();

export default eventHandlerQueue;
