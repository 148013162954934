import { Controller } from '@hotwired/stimulus';
import buildSettings from './helpers/build_settings';

export default class extends Controller {
  static targets = ['datepicker'];

  static values = {
    settings: { type: String, default: '' },
  };

  connect() {
    $(this.datepickerTarget).datepicker(buildSettings(this.settingsValue));
  }
}
