export default class Logger {
  static log(message, force = false) {
    if (!force) {
      return;
    }

    // These console logs are being allowed temporarily while we debug the voice pick project
    // Once everything is working and is stable, they can be removed completely
    // eslint-disable-next-line no-console
    console.log(message);
  }

  static warn(message) {
    Logger.log(message, true);
  }

  static logToScreen(message) {
    const messageLogElement = document.getElementById('message-logs');

    if (messageLogElement) {
      const newMessage = document.createElement('div');
      newMessage.classList.add('message');
      newMessage.textContent = message;
      messageLogElement.appendChild(newMessage);

      while (messageLogElement.children.length > 2) {
        messageLogElement.removeChild(messageLogElement.firstChild);
      }
    }
  }
}
