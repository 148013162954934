import { Controller } from '@hotwired/stimulus';
import bump from './sounds/bump';
import coin from './sounds/coin';

export default class extends Controller {
  static targets = ['sound'];

  connect() {
    switch (this.soundTarget.innerText) {
      case 'bump':
        bump().play();
        break;
      case 'coin':
        coin().play();
        break;
      default:
    }
  }
}
