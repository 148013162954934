import string from '../../../helpers/string_helper';

const removeIconDelay = process.env.RAILS_ENV === 'test' ? 1000 : null;

const focusFirstInput = (element) => {
  const firstInput = element.querySelector('input');
  if (firstInput) {
    firstInput.focus();
  }
};

const buildInputHash = (name) => {
  return $(`div[data-voice-input-name-value='${name}']`)
    .find('label + input')
    .map(function() {
      const input = $(this);
      const label = input.prev('label');
      return {
        labelText: label.text().trim().toLowerCase(),
        inputId: input.attr('id'),
      };
    })
    .get();
};

const setValueAndReturnForm = (elementId, value) => {
  const element = document.getElementById(elementId);
  element.value = value;
  return element.closest('form');
};

const appendIcon = (target) => {
  if (!target) {
    return null;
  }

  const iconId = string.generateHandlerObjectId();
  const speakIcon = document.createElement('i');
  speakIcon.id = iconId;
  speakIcon.className = 'fa fa-volume-up';
  target.appendChild(speakIcon);

  return iconId;
}

const removeIcon = (iconId) => {
  if (!iconId) {
    return;
  }

  const element = document.getElementById(iconId);

  if (!element) {
    return;
  }

  if (removeIconDelay) {
    setTimeout(() => element.remove(), removeIconDelay);
  } else {
    element.remove();
  }
}

export default {
  appendIcon,
  buildInputHash,
  focusFirstInput,
  removeIcon,
  setValueAndReturnForm,
};
