import DOM from './dom_helper';
import Logger from '../../../helpers/logger';
import speechRecognition from '../store/speech_recognition';
import utterance from '../store/utterance';

class Speaker {
  async call(messageHash) {
    if (!messageHash || messageHash.processedAt) {
      return;
    }

    await this.#speaker(messageHash)
      .then((iconId) => {
        DOM.removeIcon(iconId);
        messageHash.onDone();
      });
  }

  // private

  async #speaker(messageHash) {
    const { message, messageTarget, processedAt } = messageHash;

    if (processedAt) {
      return Promise.resolve();
    }

    const iconId = DOM.appendIcon(messageTarget);

    return new Promise((resolve) => {
      speechSynthesis.cancel();
      speechSynthesis.speak(
        utterance.call(
          message,
          () => {
            Logger.log('[UTTERANCE ENDED]');
            speechRecognition.resume('utterance.onend');
            resolve(iconId);
          },
          () => {
            Logger.warn(`[UTTERANCE ERROR] ${event.error}`);
            speechRecognition.resume('utterance.onerror');
            resolve(iconId);
          },
        )
      );
    });
  }
}

export default Speaker;
