import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'quickOrderLimitEnabled',
    'quickOrderLimitPrice',
    'quickOrderLimitConvertToRequisition',
  ];

  static disableAndUncheckInput(target, disableParent = false) {
    if (disableParent) {
      target.parentElement.classList.add('disabled');
    }
    target.setAttribute('disabled', true);
    target.checked = false; // eslint-disable-line no-param-reassign
  }

  static enableInput(target, check = false) {
    target.removeAttribute('disabled');
    if (check) {
      target.setAttribute('checked', true);
    }
    target.parentElement.classList.remove('disabled');
  }

  connect() {
    this.disableInputsIfQuickOrderLimitUnchecked();
    this.quickOrderLimitEnabledTarget.addEventListener(
      'change',
      this.toggleDisablableInputs.bind(this),
    );
  }

  disableInputsIfQuickOrderLimitUnchecked() {
    if (!this.quickOrderLimitEnabledTarget.checked) {
      this.constructor.disableAndUncheckInput(this.quickOrderLimitPriceTarget);
      this.constructor.disableAndUncheckInput(
        this.quickOrderLimitConvertToRequisitionTarget,
        true,
      );
    }
  }

  toggleDisablableInputs() {
    if (this.quickOrderLimitEnabledTarget.checked) {
      this.constructor.enableInput(this.quickOrderLimitPriceTarget);
      this.constructor.enableInput(
        this.quickOrderLimitConvertToRequisitionTarget,
        true,
      );
    } else {
      this.disableInputsIfQuickOrderLimitUnchecked();
    }
  }
}
